import React from "react";
import { Link } from "react-router-dom";
import TeamDetails1 from "../../assets/images/team_details/member-01.png"
import TeamDetails2 from "../../assets/images/team_details/member-02.png"
import TeamDetails3 from "../../assets/images/team_details/member-03.png"
import TeamDetails4 from "../../assets/images/team_details/member-04.png"
import TeamDetails5 from "../../assets/images/team_details/Deneme.pdf"

function Member() {
  return (
    <div>
      <section id="member-details" className="member-details-main-block">
        <div className="container">
        <h3 className="bio-heading">Ticaret Sicil Gazeteleri</h3>

<br></br>
<br></br>

          <div className="row">
            <div className="col-lg-12">
              <div className="member mb_60">
                <div className="row">
                  <div className="col-lg-6">

                  <h6>Ticaret Sicil Gazetesi, 25 Eylül 2024, 11172 Sayılı İlanı</h6> 
                    <div>
                    
                    <a href={require("../../assets/images/team_details/EylulAyi.pdf")} target="_blank" rel="noopener noreferrer"> <h7>Görüntüle</h7></a>
                    <br></br>
                    <br></br>
                                        
                  <h6>Ticaret Sicil Gazetesi, 20 Haziran 2024, 11105 Sayılı İlanı</h6> 

                    <a href={require("../../assets/images/team_details/HaziranAyi.pdf")} target="_blank" rel="noopener noreferrer"> <h7>Görüntüle</h7></a>
                    <br></br>
                    <br></br>
                  <h6>Ticaret Sicil Gazetesi, 24 Mayıs 2024, 11089 Sayılı İlanı</h6> 
                   
                    <a href={require("../../assets/images/team_details/MayisAyi.pdf")} target="_blank" rel="noopener noreferrer">
  <h7>Görüntüle</h7>
</a>

                    {/* <a href={require("../../assets/images/team_details/MayisAyi.pdf")} download="MayısAyıSicilGazetesi"> <h6>Mayıs Ayı Değişiklikler</h6></a> */}

                    </div>
                    
                    {/* <div className="member-img">
                      <img
                        src={TeamDetails1}
                        className="img-fluid"
                        alt=""
                      />


                    </div> */}
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="member-details">

                      {/* <div className="member-bio">

                      
                        <h5 className="bio-heading">Türkiye Ticaret Sicil Gazetesi</h5>
                        <p className="mb_30">24 Mayıs 2024 Sayı : 11089 Sayfa : 279</p>
                      </div> */}
                      {/* <p className="mb_30">
                        Anderson Elizabeth is a Certified Architecture Engineer Over
                        10 Years Experience in this and Developing for next
                        generations Services.{" "}
                      </p> */}
                      {/* <div className="bio-social mb_60">
                        <ul>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-facebook-app-symbol" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-linkedin" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-pinterest" />
                            </Link>
                          </li>
                        </ul>
                      </div> 
                      <div className="member-info">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="member-info-lst mb_40">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-ribbon" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Experience</p>
                                <h6 className="member-info-heading">
                                  More Than 10 years
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="member-info-lst mb_40">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-telephone-call" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Phone</p>
                                <h6 className="member-info-heading">
                                  +998-09-07-0342
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="member-info-lst">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-email-1" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Email</p>
                                <h6 className="member-info-heading">
                                  manager@rexain.com
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="member-info-lst">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-fax" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Fax</p>
                                <h6 className="member-info-heading">+2498098909</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <img
                        src={TeamDetails2}
                        className="img-fluid"
                        alt=""
                      /> 
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className="about-member">
                <div className="row">
                  <div className="col-lg-6">
                    <h5 className="about-member-heading mb_40">
                      About Anderson Elizabeth
                    </h5>
                    <p>
                      I am a dedicated and passionate architectural engineer, I bring
                      a unique blend of creativity and technical expertise to the
                      world of construction and design. With a profound love for both
                      the artistic and engineering aspects of architecture.
                    </p>

                    <div className="progress-block mb_60">
                      <h6 className="progress-heading">
                        Architecture Design<strong>88%</strong>
                      </h6>
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100" style={{ height: '12px' }}>
                        <div className="progress-bar" style={{ width: '88%' }}></div>
                      </div>
                      <h6 className="progress-heading">
                        Structural Design<strong>95%</strong>
                      </h6>
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" style={{ height: '12px' }}>
                        <div className="progress-bar" style={{ width: '95%' }}></div>
                      </div>
                      <h6 className="progress-heading">
                        Building Codes and Regulations<strong>90%</strong>
                      </h6>
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ height: '12px' }}>
                        <div className="progress-bar" style={{ width: '90%' }}></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="member-form">
                      <h3 className="member-form-heading mb_40">
                        Feel Free to Write Our Team Experts Here!
                      </h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            autoComplete="on"
                            placeholder="Your Name"
                          />
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            autoComplete="on"
                            placeholder="Phone number"
                          />
                        </div>
                        <div className="col-lg-6">
                          <input
                            type="email"
                            className="form-control"
                            id="mail"
                            placeholder="Enter address"
                          />
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            placeholder="subject"
                          />
                        </div>
                        <div className="col-lg-12">
                          <textarea
                            className="form-control mb_40"
                            id="message"
                            placeholder="Write Message"
                            rows={5}
                            defaultValue={""}
                          />
                        </div>
                        <div className="submit-btn">
                          <button
                            type="submit"
                            className="btn pointer-zoom btn-primary"
                          >
                            Submit Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section id="member-details" className="member-details-main-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="member mb_60">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="member-img">
                      {/* <img
                        src={TeamDetails3}
                        className="img-fluid"
                        alt=""
                      /> 
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="member-details">
                      {/* <div className="member-bio">
                        <h5 className="bio-heading">Türkiye Ticaret Sicil Gazetesi</h5>
                        <p className="mb_30">20 Haziran 2024 Sayı : 11105 Sayfa : 332</p>
                      </div> *
                      {/* <p className="mb_30">
                        Anderson Elizabeth is a Certified Architecture Engineer Over
                        10 Years Experience in this and Developing for next
                        generations Services.{" "}
                      </p> 
                      {/* <div className="bio-social mb_60">
                        <ul>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-facebook-app-symbol" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-linkedin" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" target="_blank" title="">
                              <i className="flaticon-pinterest" />
                            </Link>
                          </li>
                        </ul>
                      </div> 
                      <div className="member-info">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="member-info-lst mb_40">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-ribbon" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Experience</p>
                                <h6 className="member-info-heading">
                                  More Than 10 years
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="member-info-lst mb_40">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-telephone-call" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Phone</p>
                                <h6 className="member-info-heading">
                                  +998-09-07-0342
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="member-info-lst">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-email-1" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Email</p>
                                <h6 className="member-info-heading">
                                  manager@rexain.com
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="member-info-lst">
                              <div className="member-icon">
                                <Link to="#" title="">
                                  <i className="flaticon-fax" />
                                </Link>
                              </div>
                              <div className="member-info-dtl">
                                <p className="member-info-txt">Fax</p>
                                <h6 className="member-info-heading">+2498098909</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> *
                      {/* <img
                        src={TeamDetails4}
                        className="img-fluid"
                        alt=""
                      /> 
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="about-member">
                <div className="row">
                  <div className="col-lg-6">
                    <h5 className="about-member-heading mb_40">
                      About Anderson Elizabeth
                    </h5>
                    <p>
                      I am a dedicated and passionate architectural engineer, I bring
                      a unique blend of creativity and technical expertise to the
                      world of construction and design. With a profound love for both
                      the artistic and engineering aspects of architecture.
                    </p>

                    <div className="progress-block mb_60">
                      <h6 className="progress-heading">
                        Architecture Design<strong>88%</strong>
                      </h6>
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100" style={{ height: '12px' }}>
                        <div className="progress-bar" style={{ width: '88%' }}></div>
                      </div>
                      <h6 className="progress-heading">
                        Structural Design<strong>95%</strong>
                      </h6>
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" style={{ height: '12px' }}>
                        <div className="progress-bar" style={{ width: '95%' }}></div>
                      </div>
                      <h6 className="progress-heading">
                        Building Codes and Regulations<strong>90%</strong>
                      </h6>
                      <div className="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ height: '12px' }}>
                        <div className="progress-bar" style={{ width: '90%' }}></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="member-form">
                      <h3 className="member-form-heading mb_40">
                        Feel Free to Write Our Team Experts Here!
                      </h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            autoComplete="on"
                            placeholder="Your Name"
                          />
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            autoComplete="on"
                            placeholder="Phone number"
                          />
                        </div>
                        <div className="col-lg-6">
                          <input
                            type="email"
                            className="form-control"
                            id="mail"
                            placeholder="Enter address"
                          />
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            placeholder="subject"
                          />
                        </div>
                        <div className="col-lg-12">
                          <textarea
                            className="form-control mb_40"
                            id="message"
                            placeholder="Write Message"
                            rows={5}
                            defaultValue={""}
                          />
                        </div>
                        <div className="submit-btn">
                          <button
                            type="submit"
                            className="btn pointer-zoom btn-primary"
                          >
                            Submit Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Member;
